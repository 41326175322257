import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Slider from "../components/Slider"
import WhoAreWe from "../components/elements/WhoAreWe"
import Services from "../components/Services"

import { Background } from "../config/assets"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* Start Slider Area   */}
    <Slider />
    {/* End Slider Area   */}

    {/* Start Who are we Section */}
    <div
      className="service-area creative-service-wrapper ptb--120 bg_color--2"
      id="service"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="feature-area">
              <span>WE ARE TEKMENS</span>
              <h3 className="title mt--20 fontWeight500 lineheight--1-8">
                TEKMENS is a professional organization unlocking bespoke,
                off-the-shelf engineering solutions with integrated competences
                of environmental science, engineering, machinery, and
                technology.
              </h3>
              <h3 className="title mt--20 fontWeight500 lineheight--1-8">
                Our primary business sectors are, CONSTRUCTION, ENVIRONMENT and
                MEP
              </h3>
            </div>
          </div>
        </div>
        <div className="row service-one-wrapper mt--30">
          <WhoAreWe variant="dark" />
        </div>
      </div>
    </div>
    {/* End Who are we Section */}

    {/* Start Service Area  */}
    <div className="service-area creative-service-wrapper pt--120 pb--50 bg_color--1">
      {/* <Parallax bgImage={Background.Paralax_Slider_01} strength={700}> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="section-title text-center service-style--1 mb--30"> */}
            <div className="section-title text-center">
              <h2 className="title">OUR SOLUTIONS</h2>
            </div>
          </div>
        </div>
        <div className="row creative-service">
          <Services
            serviceId="home"
            item="12"
            column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
          />
        </div>
        <div className="product-explore-button mt--50 text-center">
          <a
            className="rn-button-style--2 btn-solid btn-center"
            href="/sectors"
          >
            Explore Our Sectors
          </a>
        </div>
      </div>
      {/* </Parallax> */}
    </div>
    {/* End Service Area  */}
  </Layout>
)

export default IndexPage
